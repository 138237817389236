.kreativ-wrap {


  .kreativ {
    display: block;
    position: relative;
    //line-height: 0;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .kreativ .kreativ-in {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: .5s all ease;
    padding: 20px;
  }
}

//
//.square_wrapper .square_wrapper_container {
//  position: absolute;
//  left: 0;
//  top: 50px;
//  right: 0;
//  bottom: 0;
//  width: 100%;
//  height: 100%;
//  display: flex;
//  flex-wrap: nowrap;
//  flex-direction: row;
//  justify-content: center;
//  align-items: center;
//  align-content: center;
//}

.square_wrapper .square_wrapper_container img {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  min-width: 0;
  display: block;
  width: auto;
  height: auto;
  max-height: 75%;
  max-width: 100%;
}

.square_wrapper .square_wrapper_container--in-list img {
  max-height: 45%;
}


  .kreativ-wrap .kreativ3 .kreativ-in .kreativ-title--in-list {
    bottom: 20px!important;
    color: #333;
  }

  .kreativ-wrap .kreativ3 .kreativ-in .kreativ-title {
    top: 20px;
  }

  .kreativ-wrap .kreativ .kreativ-in .kreativ-title {
    position: absolute;
    //top: auto;
    bottom: -70px;
    left: 20px;
    right: 20px;
  }

  .square_wrapper {
    padding-bottom: 100%;
    position: relative;
    border: solid 1px #ccc;
  }

  .square_wrapper .square_wrapper_container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

.kreativ-wrap .kreativ3 .kreativ-in .kreativ-title .kreativ-title__go-to-link {
  position: absolute;
  right: 0;
  left: auto;
  top: auto;
  bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fefefe;
}

.kreativ-wrap .kreativ3 .kreativ-in .kreativ-title .kreativ-title__go-to-link__text {
  opacity: 1;
  padding-right: 15px;
}

@media screen and (min-width: 62.5em){
  .kreativ-wrap .kreativ3:hover .kreativ-in {
    //background: rgba(0,157,152,.8);
    background: rgba(87, 87, 87, 0.8);
  }
}

.kreativ-title__go-to-link{
  .fa-chevron-right{
    height: 40px;
    width: 40px;
    padding-top: 10px;
    &:hover{
      text-decoration: none !important;
      background-color: white;
      color: rgb(87,87,87, 0.8);
    }
  }

  .fa-border{
    border: solid 0.08em #fff;
    border-radius: 0;
  }

  &:hover{
    text-decoration: none;
  }
}


//@media print, screen and (min-width: 40em){
  ul.tabs {
    border: 0;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    display: table;
    width: 100%;
  }
//}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none;
}
.tabs::after, .tabs::before {
  display: table;
  content: ' ';
}

//@media print, screen and (min-width: 40em){
  ul.tabs li.tabs-title {
    width: auto;
    display: table-cell;
    float: none;
  }
//}

//@media print, screen and (min-width: 40em){
  ul.tabs .tabs-title>a:focus, ul.tabs .tabs-title>a[aria-selected=true] {
    background: #333;
    color: #fff;
  }
//}

//@media print, screen and (min-width: 40em){
  ul.tabs .tabs-title>a {
    display: block;
    padding: 11px 0;
    text-align: center;
    font-size: 18px;
    line-height: 1;
    color: #333;
  }
//}

/*.tabs-title>a:focus, .tabs-title>a[aria-selected=true] {
  background: #e6e6e6;
  color: #7776b5;
}*/

.tabs-title>a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: .75rem;
  line-height: 1;
  color: #7776b5;
}

.tabs::after {
  clear: both;
}

.tabs::after, .tabs::before {
  display: table;
  content: ' ';
}

.tabs-content {
  border: 0;
}

.tabs-content {
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all .5s ease;
}

.tabs-content .tabs-panel {
  padding: 25px 0;
}


@media screen and (max-width:991px){
  .kreativ-title--in-list > p{

      display: none;

  }
    .kreativ-title__go-to-link {
        .kreativ-title__go-to-link__text, .fa-chevron-right, .fa-chevron-right:hover {
            color: #333;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .offset-1 {
        margin: 0;
    }
}

@media screen and (max-width: 39.9375em){
  .lead {
    font-size: 110%;
    line-height: 1.4;
  }
}


.btn-product {
    padding: 0 12px;
    background: $dark-gray;
    background-size: 20px 17px;
    font-size: 14px;
    color: white;
    margin: 0;
    font-weight: 400;

    .fa-chevron-right {
        padding-left: 0;
    }
}






